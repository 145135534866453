const m = require('mithril')
const uson = require('uson')
const yaml = require('js-yaml')

const data = require('../lib/data')
const cols = require('../schema/collections')

const defaultKeywords = ['_id']
const PER_PAGE = 50

let pageStore = {}
let model = null
let q
let submittedQ
let updateTrigger = null
let updateRunning = null
let offset = 0

function refresh (vnode) {
  return () => {
    loadPageStore(vnode)
  }
}

function processQuery (col) {
  const query = {}
  const keywords = defaultKeywords
  if (col.keywords) {
    keywords.push(...col.keywords)
  }
  if (submittedQ) {
    const conds = { $and: [], $or: [] }
    // parse
    let parsed
    try {
      parsed = uson.parse(submittedQ, 'object')
    } catch (e) {
      pageStore.error = `USON parse failed:\n  ${e} `
      return null
    }
    console.log('uson parsed:', parsed)
    if (parsed.id) {
      parsed._id = parsed.id
      delete parsed.id
    }
    // add fulltext args
    const fulltexts = []
    Object.keys(parsed).forEach(k => {
      if (k.match(/^\d+$/)) {
        fulltexts.push(parsed[k])
        delete parsed[k]
      }
    })
    console.log('final filters:', parsed)
    conds.$and.push(parsed)

    // fulltext search
    for (const ft of fulltexts) {
      for (const k of keywords) {
        if (col.objectId !== false && k === '_id' && ft.length !== 24) {
          continue
        }
        conds.$or.push({ [k]: (col.objectId !== false && k === '_id') ? ft : { $regex: ft, $options: 'i' } })
      }
    }
    if (conds.$or.length > 0) {
      query.$or = conds.$or
    }
    if (conds.$and.length > 0) {
      query.$and = conds.$and
    }
  }
  return query
}

function loadData (vnode, offset = 0) {
  const col = cols[vnode.attrs.col]
  if (!col) {
    m.route.set('/db')
    return null
  }
  const query = processQuery(col)
  const params = {
    model: col.model,
    query,
    limit: PER_PAGE,
    offset,
    sort: col.sort
  }
	// Could generate false positives, but it is okay for now
	if(JSON.stringify(params.query).includes("userId")) {
		params.sort = { archived: 1, created: -1 }
	}
  console.log('Query params:', params)
  return data.dataQuery(params, offset > 0 ? false : (Object.keys(query).length > 0))
}

function loadPageStore (vnode) {
  pageStore = {}
  model = vnode.attrs.col
  return loadData(vnode).then(res => {
    if (res.errors) {
      pageStore.error = yaml.safeDump(res.errors[0])
    } else {
      pageStore.dataQuery = res.data.dataQuery
      pageStore.dataQueryCount = res.data.dataQueryCount
    }
    // m.redraw()
  })
}

function loadMore (vnode) {
  return () => {
    offset = offset + PER_PAGE
    loadData(vnode, offset).then(res => {
      pageStore.dataQuery.push(...res.data.dataQuery)
      m.redraw()
    })
  }
}

function changeQ (e) {
  q = e.target.value
}

function removeFilters (vnode) {
  return () => {
    const baseRoute = `/db/${vnode.attrs.col}`
    q = ''
    m.route.set(baseRoute, null)
    updateTrigger = true
    // loadPageStore(vnode)
  }
}

function doSearch (vnode) {
  return function (e) {
    const baseRoute = `/db/${vnode.attrs.col}`
    let route = baseRoute
    if (q) {
      route = `${baseRoute}/filter/${encodeURIComponent(q)}`
    }
    pageStore = {}
    if (m.route.get() !== route) {
      updateTrigger = true
      m.route.set(route)
    } else {
      submittedQ = null
    }
    return false
  }
}

module.exports = {
  oninit (vnode) {
    updateRunning = true
    q = vnode.attrs.q || undefined
    submittedQ = q
    loadPageStore(vnode).then(() => {
      updateRunning = false
    })
  },
  onupdate (vnode) {
    if (model && model !== vnode.attrs.col) {
      q = undefined
      submittedQ = undefined
      model = null
      pageStore = {}
      m.redraw()
      loadPageStore(vnode)
    }
    if (updateTrigger && vnode.attrs.q !== submittedQ) {
      submittedQ = vnode.attrs.q
      q = submittedQ
      updateTrigger = false
      pageStore = {}
      loadPageStore(vnode)
    } else if (vnode.attrs.q !== submittedQ && !updateRunning) {
      submittedQ = vnode.attrs.q
      q = submittedQ
      updateRunning = true
      pageStore = {}
      loadPageStore(vnode).then(() => {
        updateRunning = false
      })
    }
  },
  onremove () {
    q = undefined
    model = null
    pageStore = {}
  },
  view (vnode) {
    const col = cols[vnode.attrs.col]
    return m('div', { style: 'margin-bottom: 2em;' }, [
      m('.level', [
        m('.level-left', [
          m('.level-item', [
            m('.title.is-3', [
              m(m.route.Link, { href: '/db' }, [m('i', { class: 'fas fa-database' }), 'Database']),
              ' → ',
              m('span', `${col.title}`)
            ])
          ])
        ]),
        m('.level-right', [
          m('.level-item', [
            m('button.button', { onclick: refresh(vnode) }, [m('span.icon.is-small', m('i.fas.fa-sync')), m('span', 'Refresh')])
          ])
        ])
      ]),
      m('form.form', { onsubmit: doSearch(vnode) }, [
        m('.level', [
          m('.level-left', [
            /* m('.level-item', [
              m('label.label', 'Filter'),
            ]), */
            m('.level-item', [
              m('input.input', {
                style: 'width: 700px;',
                placeholder: `Filter ${vnode.attrs.col} ..`,
                oninput: changeQ,
                value: q
              })
            ]),
            m('.level-item', [
              m('button.button', { type: 'submit' }, 'Submit')
            ])
          ])
        ])
      ]),
      typeof pageStore.dataQueryCount === 'number' ? m('div', { style: 'margin-bottom: 1em' }, [
        m('.level', [
          m('.level-left', [
            m('.level-item', m('div', [
              'Results: ',
              m('b', pageStore.dataQueryCount)
            ])),
            m('.level-item', [
              m('button.button.is-primary', { onclick: removeFilters(vnode) }, 'Remove filters')
            ])
          ])
        ])
      ]) : '',
      pageStore.dataQuery ? m('div', [
        m('table.table', { style: 'width: 100%' }, [
          m('thead', col.cols.map(c => {
            return m('th', c.name)
          })),
          m('tbody', pageStore.dataQuery.map(item => {
            return m('tr', { key: item._id }, col.cols.map(c => {
              let val = item[c.key]
              if (c.render) {
                val = c.render.call(vnode, val, item)
              }
              return m('td', { style: c.align === 'center' ? 'text-align: center;' : '' }, val)
            }))
          })),
          pageStore.dataQuery.length === 0 ? m('div', { style: 'margin-top: 1em;' }, 'No items found') : ''
        ]),
        pageStore.dataQuery.length !== pageStore.dataQueryCount && ((pageStore.dataQueryCount && pageStore.dataQueryCount > PER_PAGE) || (pageStore.dataQuery && q === undefined)) ? m('button.button', { onclick: loadMore(vnode) }, 'Load more items') : ''
      ]) : (pageStore.error ? m('pre.notification.is-danger', pageStore.error) : m('div', 'Loading data ..'))
    ])
  }
}
