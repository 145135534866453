/* global Notification, alert */

const m = require('mithril')
const dateFns = require('date-fns')
const yaml = require('js-yaml')
const jwt = require('jsonwebtoken')
const Colored = require('../components/colored')

const linkComponent = require('../components/link')
const countComponent = require('../components/count')
const asyncListComponent = require('../components/asynclist')
const control = require("./control");

const nullDef = m('span', { style: 'color: silver;' }, '(null)')
const emptyDef = m('span', { style: 'color: silver;' }, '(empty)')

function compileColVal (c, item, vnode, val = null) {
  if (val === null) {
    val = item[c.key]
  }
  if (c.render) {
    val = c.render.call(vnode, val, item, vnode)
  }
  if (val === null || val === undefined) {
    return nullDef
  }
  return val
}

function sendNotification (val) {
  return new Notification(val)
}

function notify (val) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
  } else if (Notification.permission === 'granted') {
    sendNotification(val)
  } else if (Notification.permission !== 'denied') {
    // Otherwise, we need to ask the user for permission
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        throw new Error('not granted')
      }
    }).then(() => {
      sendNotification(val)
    })
  }
}

const EditArrayValue = {
  oninit (vnode) {
    this._input = vnode.attrs.value
    this._error = null
    this.edited = false
    this.changeInput = (e) => {
      this._input = e.target.value
    }
    this.cancelEdit = () => {
      this._input = vnode.attrs.value
      this._error = null
      this.edited = false
    }
    this.startEdit = () => {
      this.edited = true
    }
    this.submitForm = () => {
      const model = vnode.attrs.model
      const item = vnode.attrs.item
      const arrayId = vnode.attrs.arrayId
      const column = vnode.attrs.column
      if (this._input === vnode.attrs.value) {
        this.edited = false
        return false
      }
      control.objectUpdateSimple({
        model,
        id: item._id,
        arrayId,
        data: {
          [column.key]: this._input
        }
      }).then((res) => {
        if (res.errors) {
          const e = res.errors[0]
          this._error = `Error: ${e.message}`
          // + (e.extensions && e.extensions.exception ? `\n\nStacktrace:\n  ${e.extensions.exception.stacktrace}` : '')
        }
        if (res.data.objectUpdateSimple) {
          this.edited = false
          vnode.attrs.updateDetail()
        }
      })
      return false
    }
  },
  view (vnode) {
    const columnSchema = vnode.attrs.edit
      ? vnode.attrs.value
      : null
    const isChanged = (this._input !== vnode.attrs.value)
    return m('td', [
      this.edited
        ? [
          m('form', { onsubmit: this.submitForm }, [
            this._error ? m('pre.notification.is-danger', this._error) : '',
            m('input.input', { oninput: this.changeInput, value: this._input }),
            m('.level', { style: 'margin-top: 0.5em;' }, [
              m('.level-left', [
                m('.level-item', [
                  m('button.button.is-primary', { type: 'submit', disabled: !isChanged }, 'Save')
                ]),
                m('.level-item', [
                  m('button.button', { type: 'button', onclick: this.cancelEdit }, 'Cancel')
                ])
              ])
            ])
          ])
        ]
        : [
          m('span', vnode.attrs.value),
          ' ',
          columnSchema ? m('a', { onclick: this.startEdit }, '(edit)') : ''
        ]
    ])  }
}

const renderers = {

  maxLength (val, max = 30) {
    if (!val) {
      return nullDef
    }
    return val.length > max ? val.substring(0, max) + ' ..' : val
  },
  small (val) {
    return m('small', val)
  },
  default (val) {
    if (!val) {
      return nullDef
    }
    return val
  },
  boolean (val) {
    if (val === null) {
      return nullDef
    }
    return val ? 'Yes' : 'No'
  },
  planShifts (_, item) {
    if (!item || !item.days) {
      return nullDef
    }
    return item.days.reduce((sum, c) => (sum + (c.shifts ? c.shifts.length : 0)), 0)
  },
  shiftDuration (_, item) {
    if (!item || !item.period) {
      return nullDef
    }
    const len = (((new Date(item.period.end) - new Date(item.period.start)) / 1000) / 60)
    return [`${len}m `, m('small', '(' + (len / 60) + 'h)')]
  },
  name (val, item) {
    let out = [item.firstName, item.lastName].join(' ').trim()
    if (!out && val) {
      out = val
    } else if (!out && item.name) {
      out = item.name
    } else if (!out) {
      return '(null)'
    }
    return m('b', renderers.maxLength(out))
  },
  nameBig (...args) {
    return m('span.title.is-5', renderers.name(...args))
  },
  array (conf,editableRowKey, rowKey) {
    if (!conf) {
      conf = []
    }
    return function (arr, item, vnode) {
      let editConf = null
      let prop = null
      if(editableRowKey){
        prop = vnode.attrs.cols[vnode.attrs.col].edit.schema.properties
        editConf = prop[editableRowKey].properties
      }
      if (!arr || !Array.isArray(arr)) {
        return nullDef
      }
      if (arr.length < 1) {
        return m('div', emptyDef)
      }
      return m('table.table', [
        m('thead', m('tr', conf.map(c => {
          return m('th', c.name)
        }))),
        m('tbody', arr.map(x => {
          return m('tr', conf.map(c => {
            let val = x[c.key]
            let editable = editConf ? editConf[c.key]  : null
            if (c.render) {
              val = c.render.call(vnode, val, item, vnode)
            }
            if( editable ) {
             return m(EditArrayValue, {
                column: c,
                value: val,
                vnode:vnode,
                arrayId: x[rowKey],
                item: item,
                edit:editConf[c.key],
                model: vnode.attrs.col
             })

            }
            else {
              return m('td', val)
            }
          }))
        }))
      ])
    }
  },
  simpleLink (type, small = false) {
    return function (id) {
      if (!id) {
        return nullDef
      }
      const link = m(m.route.Link, { href: `/db/${type}/${id}` }, id)
      if (small) {
        return m('small', link)
      }
      return link
    }
  },
  link (type, small = false, resolve = false) {
    return function (id) {
      if (!id) {
        return nullDef
      }
      const link = m(linkComponent, Object.assign({ linkType: type, linkId: id, resolve }, this.attrs))
      if (small) {
        return m('small', link)
      }
      return link
    }
  },
  asyncCount (model, key, commonQuery = {}) {
    return function(_, item) {
      const query = { ...commonQuery }
      query[key] = item._id
      return m(countComponent, Object.assign({ model, query }, this.attrs))
    }

  },
  id (id) {
    const copied = false
    function copyItem (val) {
      return () => {
        navigator.clipboard.writeText(val)
        notify(`Copied to clipboard: ${val}`)
      }
    }
    return m('div', [
      id,
      m('a.ds-copy-id', [
        m('i', { class: 'far fa-copy', style: 'margin-left:7px;', onclick: copyItem(id) }),
        copied ? m('span', ' copied!') : ''
      ])
    ])
  },
  date (date) {
    if (!date) {
      return nullDef
    }
    const time = new Date(date)
    return m('small', { title: (new Date(date)).toISOString() }, dateFns.formatDistanceToNow(time) + (dateFns.isFuture(time) ? ' in future' : ' ago'))
  },
  dateFull (date) {
    if (!date) {
      return nullDef
    }
    return [(new Date(date)).toISOString(), m('small', [' (', renderers.date(date), ')'])]
  },
  formatedDate (date,format){
    if (!date || !format) {
      return nullDef
    }
    const time = new Date(date)
    const res = dateFns.format(time, format)
    return  m('small', res)
  },
  periodPart (type, wrap = true, short = false) {
    return function (_, item) {
      if (!item || !item.period) {
        return nullDef
      }
      const date = item.period[type]
      if (!date) {
        return nullDef
      }
      const time = new Date(date)
      const res = dateFns.format(time, short ? 'HH:mm' : 'yyyy-MM-dd HH:mm')
      return wrap ? m('small', res) : res
    }
  },
  yamlDump (val) {
    return val !== undefined ? m(Colored, { text: renderers.maxLength(yaml.safeDump(val), 65) }) : nullDef
  },
  yamlDumpFull (val) {
    return val !== undefined ? m(Colored, { text: yaml.safeDump(val) }) : nullDef
  },
  password (val) {
    return val ? 'Yes (censored)' : null
  },
  jwt (key = false, type = 'string') {
    return val => {
      const dec = jwt.decode(val)
      if (!dec) {
        return nullDef
      }
      if (!key) {
        return m(Colored, { text: yaml.safeDump(dec) })
      }
      const o = dec[key]
      if (type === 'date') {
        return this.date(o * 1000)
      }
      if (type === 'dateFull') {
        return this.dateFull(o * 1000)
      }
      if (type === 'target') {
        return this.link('users')(o)
      }
      return o
    }
  },
  asyncList (collection, extractor) {
    return function (id, item, vnode) {
      return m(asyncListComponent, Object.assign({ collection, loadId: id, extractor: extractor(item) }, this.attrs))
    }
  }
}

module.exports = {
  nullDef,
  emptyDef,
  compileColVal,
  renderers
}
